@import "../../styles/_chunk";

.publisher-carousel {
  margin-bottom: 3.5rem;

  @include media-breakpoint-up(sm) {
    margin-left: -10px;
    margin-right: -10px;
  }

  .slide-container {
    position: relative;
  }

  .deal-number-bar {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: $blue-grey-600;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 1px 0;
  }

  .publisher-box {
    background: radial-gradient(ellipse at center, $white 40%, $grey-1 100%);
    border-radius: $border-radius;
    border: 1px solid $grey-1;
    text-align: center;
    height: 100px;
    color: $text-black;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 700;

    @include theme(light) {
      background: $white;
      border-color: $grey-3;
    }

    @include media-breakpoint-up(xl) {
      height: 110px;
    }

    &:hover {
      background: $grey-1;
    }

    &.with-discount-bar {
      padding-bottom: 9px;
    }

    .sega-logo {
      width: 80px;

      @include media-breakpoint-up(xl) {
        width: 90px;
      }
    }

    .ubisoft-logo {
      width: 120px;

      @include media-breakpoint-up(xl) {
        width: 130px;
      }
    }

    .warner-logo {
      width: 50px;

      @include media-breakpoint-up(xl) {
        width: 58px;
      }
    }

    .two-logo {
      width: 60px;

      @include media-breakpoint-up(xl) {
        width: 70px;
      }
    }

    .bethesda-logo {
      width: 120px;

      @include media-breakpoint-up(xl) {
        width: 130px;
      }
    }

    .rockstar-logo {
      width: 44px;

      @include media-breakpoint-up(xl) {
        width: 50px;
      }
    }

    .bandai-logo {
      width: 80px;

      @include media-breakpoint-up(xl) {
        width: 90px;
      }
    }

    .capcom-logo {
      width: 120px;

      @include media-breakpoint-up(xl) {
        width: 130px;
      }
    }

    .deepsilver-logo {
      width: 38px;

      @include media-breakpoint-up(xl) {
        width: 42px;
      }
    }

    .paradox-logo {
      width: 50px;

      @include media-breakpoint-up(xl) {
        width: 55px;
      }
    }

    .thq-nordic-logo {
      margin-top: 3px;
      width: 120px;

      @include media-breakpoint-up(xl) {
        margin-top: 6px;
        width: 130px;
      }
    }

    .plug-in-logo {
      margin-top: 3px;
      width: 80px;

      @include media-breakpoint-up(xl) {
        margin-top: 6px;
        width: 90px;
      }
    }

    .stardock-logo {
      width: 120px;

      @include media-breakpoint-up(xl) {
        width: 130px;
      }
    }

    .disney-logo {
      margin-top: 3px;
      width: 100px;

      @include media-breakpoint-up(xl) {
        margin-top: 6px;
        width: 110px;
      }
    }

    .aspyr-logo {
      width: 100px;

      @include media-breakpoint-up(xl) {
        width: 108px;
      }
    }

    .jackbox-logo {
      width: 100px;

      @include media-breakpoint-up(xl) {
        width: 110px;
      }
    }

    .squareenix-logo {
      width: 140px;

      @include media-breakpoint-up(xl) {
        width: 150px;
      }
    }

    .five-o-five-logo {
      width: 100px;

      @include media-breakpoint-up(xl) {
        width: 110px;
      }
    }

    .team-seventeen-logo {
      width: 100px;

      @include media-breakpoint-up(xl) {
        width: 120px;
      }
    }

    .focus-home-logo {
      width: 100px;

      @include media-breakpoint-up(xl) {
        width: 110px;
      }
    }

    .focus-home-entertainment-logo {
      width: 130px;

      @include media-breakpoint-up(xl) {
        width: 140px;
      }
    }

    .devolver-logo {
      width: 95px;

      @include media-breakpoint-up(xl) {
        width: 105px;
      }
    }
  }

  .arrow-btn-container {
    &.btn-right {
      @include media-breakpoint-up(xl) {
        margin-right: -30px;
      }
    }

    &.btn-left {
      @include media-breakpoint-up(xl) {
        margin-left: -30px;
      }
    }
  }

  .slick-slide {
    .publisher-box-container {
      padding: 0 10px;
    }
  }
}
